<template>
<div class="table-add" v-loading.fullscreen.lock="loading">
    <div class="table-add-header" v-if="!loading">
        <h1>Edit Faq page</h1>
        <div class="flex mt-20">
            <button class="table-lang-button" type="button" @click="langName='ua'" :class="langName=='ua'?'active':''">UA</button>
            <button class="table-lang-button ml-10" type="button" @click="langName='en'" :class="langName=='en'?'active':''">ENG</button>
        </div>
        <div class="add-inputs" style="max-width:1000px;">
            <el-form ref="pagesForm" :model="Pages" :rules="PagesRules">
                <div class="form-au" v-show="langName=='ua'">
                    <div class="item mt-20">
                        <p> Name</p>
                        <el-form-item prop="data.name_ua">
                            <el-input v-model="Pages.data.name_ua"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item mt-20">
                        <p> Name description</p>
                        <el-form-item prop="data.name_ua">
                            <el-input v-model="Pages.data.link_dec_ua"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item mt-20">
                        <p> Title</p>
                        <el-form-item prop="data.title_ua">
                            <el-input v-model="Pages.data.title_ua"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <p>Description</p>
                        <el-form-item prop="data.description_ua">
                            <el-input type="textarea" :rows="4" v-model="Pages.data.description_ua"></el-input>
                        </el-form-item>
                    </div>

                    <div class="item mt-20">
                        <p>Button1 Title</p>
                        <el-form-item prop="data.button1_title_ua">
                            <el-input v-model="Pages.data.button1_title_ua"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item mt-20">
                        <p>Button1 Url</p>
                        <el-form-item prop="data.button1_url">
                            <el-input v-model="Pages.data.button1_url"></el-input>
                        </el-form-item>
                    </div>
                     <div class="item mt-20">
                        <p>Button2 Title</p>
                        <el-form-item prop="data.button2_title_ua">
                            <el-input v-model="Pages.data.button2_title_ua"></el-input>
                        </el-form-item>
                    </div>

                    <div class="item">
                        <el-form-item prop="files.Image1">
                            <div class="upload-image">
                                <button class="bg-red">
                                    Image 1 Upload
                                    <input type="file" name="image" @change="UploadIMage($event ,'Image1')" accept="image/*">
                                </button>
                                <p :class="erros.icon?'c-red':''"> {{Pages.files.Image1&&Pages.files.Image1.name? Pages.files.Image1.name: Pages.files.Image1 ? Pages.files.Image1 :'Upload Image'}} </p>
                            </div>
                            <p v-if="erros.icon" class="error-text">Upload image</p>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <el-form-item prop="files.Image2">
                            <div class="upload-image">
                                <button class="bg-red">
                                    Image 2 Upload
                                    <input type="file" name="image" @change="UploadIMage($event ,'Image2')" accept="image/*">
                                </button>
                                <p :class="erros.icon?'c-red':''"> {{Pages.files.Image2&&Pages.files.Image2.name? Pages.files.Image2.name: Pages.files.Image2 ? Pages.files.Image2 :'Upload Image'}} </p>
                            </div>
                            <p v-if="erros.icon" class="error-text">Upload image</p>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <el-form-item prop="files.Image3">
                            <div class="upload-image">
                                <button class="bg-red">
                                    Image 3 Upload
                                    <input type="file" name="image" @change="UploadIMage($event ,'Image3')" accept="image/*">
                                </button>
                                <p :class="erros.icon?'c-red':''"> {{Pages.files.Image3&&Pages.files.Image3.name? Pages.files.Image3.name: Pages.files.Image3 ? Pages.files.Image3 :'Upload Image'}} </p>
                            </div>
                            <p v-if="erros.icon" class="error-text">Upload image</p>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <el-form-item prop="files.ImageClouds">
                            <div class="upload-image">
                                <button class="bg-red">
                                    Clouds Upload
                                    <input type="file" name="image" @change="UploadIMage($event ,'ImageClouds')" accept="image/*">
                                </button>
                                <p :class="erros.icon?'c-red':''"> {{Pages.files.ImageClouds&&Pages.files.ImageClouds.name? Pages.files.ImageClouds.name: Pages.files.ImageClouds ? Pages.files.ImageClouds :'Upload Image'}} </p>
                            </div>
                            <p v-if="erros.icon" class="error-text">Upload image</p>
                        </el-form-item>
                    </div>
                    <h1>Links Blogs</h1> 
                    <div v-if="Pages.data.links">
                    <div v-for="(item, i) in Pages.data.links" :key="i">
                        <div class="item mt-20">
                            <p> Title</p>
                            <el-form-item prop="data.links">
                                <el-input v-model="item.title_ua"></el-input>
                            </el-form-item>
                        </div>
                        <div class="item mt-20">
                            <p> Link</p>
                            <el-form-item prop="data.links">
                                <el-input v-model="item.url"></el-input>
                            </el-form-item>
                        </div>
                        <div class="item">
                            <el-form-item>
                                <div class="upload-image">
                                    <button class="bg-red">
                                        Image Upload
                                        <input type="file" name="image" @change="UploadIMage($event , `linksImage${item.id}`)" accept="image/*">
                                    </button>
                                    <p :class="erros.icon?'c-red':''"> {{Pages.files[`linksImage${item.id}`]&&Pages.files[`linksImage${item.id}`].name? Pages.files[`linksImage${item.id}`].name: Pages.files[`linksImage${item.id}`] ? Pages.files[`linksImage${item.id}`] :'Upload Image'}} </p>
                                </div>
                                <p v-if="erros.icon" class="error-text">Upload image</p>
                            </el-form-item>
                        </div>
                        <div style="padding-bottom:20px;" class="flex justify-end">
                            <button class="button red" style="background:red;" @click="deleteBlock(i)">Delete</button>
                        </div>
                    </div>
                    </div>
                    <button type="button" @click="PushBlock" class="button">Add block</button>
                    <h1>Technique</h1>
                    <div class="item mt-20">
                        <p> Title</p>
                        <el-form-item >
                            <el-input v-model="Pages.data.technique_title1_ua"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item mt-20">
                        <p> Description</p>
                        <el-form-item prop="data.technique_title2_ua">
                            <el-input v-model="Pages.data.technique_title2_ua"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <p>Text</p>
                        <el-form-item prop="data.technique_text_ua">
                            <el-input type="textarea" :rows="4" v-model="Pages.data.technique_text_ua"></el-input>
                        </el-form-item>
                    </div>
                    <h1>Product types</h1>
                    <div v-if="Pages.data.types">
                    <div class="types" v-for="(item,i) in Pages.data.types" :key="i">
                        <h4>{{i+1}}-type</h4>
                        <div class="item mt-20">
                            <p> Title</p>
                            <el-form-item >
                                <el-input v-model="item.title_ua"></el-input>
                            </el-form-item>
                        </div>
                        <h5>1-Description</h5>
                        <div class="item mt-20">
                            <p> Title</p>
                            <el-form-item >
                                <el-input v-model="item.description1_ua"></el-input>
                            </el-form-item>
                        </div>
                        <h5>2-Description</h5>
                        <div class="item mt-20">
                            <p> Title</p>
                            <el-form-item >
                                <el-input v-model="item.description2_ua"></el-input>
                            </el-form-item>
                        </div>

                        <h5>3-Description</h5>
                        <div class="item mt-20">
                            <p> Title</p>
                            <el-form-item >
                                <el-input v-model="item.description3_ua"></el-input>
                            </el-form-item>
                        </div>
                        <div class="item mt-20">
                            <p> Button title</p>
                            <el-form-item >
                                <el-input v-model="item.button_ua"></el-input>
                            </el-form-item>
                        </div>
                        <div class="item mt-20">
                            <p> URL</p>
                            <el-form-item  >
                                <el-input v-model="item.url"></el-input>
                            </el-form-item>
                        </div>
                        <div class="item">
                            <el-form-item  >
                                <div class="upload-image">
                                    <button class="bg-red">
                                        Image Upload
                                        <input type="file" name="image" @change="UploadIMage($event ,`types${i}`)" accept="image/*">
                                    </button>
                                    <p :class="erros.icon?'c-red':''"> {{Pages.files[`types${i}`]&& Pages.files[`types${i}`].name? Pages.files[`types${i}`].name: Pages.files[`types${i}`] ? Pages.files[`types${i}`] :'Upload Image'}} </p>
                                </div>
                                <p v-if="erros.icon" class="error-text">Upload image</p>
                            </el-form-item>
                        </div>
                    </div>
                    </div>

                    <h1>Why Avoir</h1>
                    <div class="item mt-20">
                        <p> Title</p>
                        <el-form-item prop="data.whyText_ua">
                            <el-input v-model="Pages.data.whyText_ua"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <el-form-item prop="files.whyImage1">
                            <div class="upload-image">
                                <button class="bg-red">
                                    Image 1 Upload
                                    <input type="file" name="image" @change="UploadIMage($event , `whyImage1`)" accept="image/*">
                                </button>
                                <p :class="erros.icon?'c-red':''"> {{Pages.files.whyImage1&&Pages.files.whyImage1.name? Pages.files.whyImage1.name: Pages.files.whyImage1 ? Pages.files.whyImage1 :'Upload Image'}} </p>
                            </div>
                            <p v-if="erros.icon" class="error-text">Upload image</p>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <el-form-item prop="files.whyImage2">
                            <div class="upload-image">
                                <button class="bg-red">
                                    Image 2 Upload
                                    <input type="file" name="image" @change="UploadIMage($event , `whyImage2`)" accept="image/*">
                                </button>
                                <p :class="erros.icon?'c-red':''"> {{Pages.files.whyImage2&&Pages.files.whyImage2.name? Pages.files.whyImage2.name: Pages.files.whyImage2 ? Pages.files.whyImage2 :'Upload Image'}} </p>
                            </div>
                            <p v-if="erros.icon" class="error-text">Upload image</p>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <el-form-item prop="files.whyImage3">
                            <div class="upload-image">
                                <button class="bg-red">
                                    Image 3 Upload
                                    <input type="file" name="image" @change="UploadIMage($event , `whyImage3`)" accept="image/*">
                                </button>
                                <p :class="erros.icon?'c-red':''"> {{Pages.files.whyImage3&&Pages.files.whyImage3.name? Pages.files.whyImage3.name: Pages.files.whyImage3 ? Pages.files.whyImage3 :'Upload Image'}} </p>
                            </div>
                            <p v-if="erros.icon" class="error-text">Upload image</p>
                        </el-form-item>
                    </div>

                    <h3>Why descriptions </h3>
                    <div v-if="Pages.data.why"> 
                    <div v-for="(item, i) in Pages.data.why" :key="i">
                        <div class="item mt-20">
                            <p> Title</p>
                            <el-form-item>
                                <el-input v-model="item.title_ua"></el-input>
                            </el-form-item>
                        </div>
                        <div class="item">
                            <p>Description</p>
                            <el-form-item>
                                <el-input type="textarea" :rows="4" v-model="item.description_ua"></el-input>
                            </el-form-item>
                        </div>
                        <div style="padding-bottom:20px;" class="flex justify-end">
                            <button class="button red" style="background:red;" @click="deleteText(i)">Delete</button>
                        </div>
                    </div>
                    </div>
                    <button type="button" @click="PushText" class="button">Add Block</button>
                    <h1>About us</h1>
                    <div class="item mt-20">
                        <p> Title</p>
                        <el-form-item prop="data.about_title_ua">
                            <el-input v-model="Pages.data.about_title_ua"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item mt-20">
                        <p> Phone</p>
                        <el-form-item prop="Pages.data.phone">
                            <el-input v-model="Pages.data.phone"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item mt-20">
                        <p> Phone title</p>
                        <el-form-item prop="data.phone_title_ua">
                            <el-input v-model="Pages.data.phone_title_ua"></el-input>
                        </el-form-item>
                    </div>
                    <h3>Emails</h3>
                    <div v-if="Pages.data.mails">
                    <div v-for="(item, i) in Pages.data.mails" :key="i">
                        <div class="item mt-20">
                            <p> Email</p>
                            <el-form-item>
                                <el-input v-model=" Pages.data.mails[i]"></el-input>
                            </el-form-item>
                        </div>

                        <div style="padding-bottom:20px;" class="flex justify-end">
                            <button class="button red" style="background:red;" @click="deleteMail(i)">Delete</button>
                        </div>
                    </div>
                    </div>
                    <button type="button" @click="PushMail" class="button">Add Mail</button>
                    <div class="item mt-20">
                        <p>Adress</p>
                        <el-form-item prop="data.adress_ua">
                            <el-input type="textarea" :rows="4" v-model="Pages.data.adress_ua"></el-input>
                        </el-form-item>
                    </div>
                </div>
                <div class="form-en mt-20" v-show="langName=='en'">
                    <div class="item mt-20">
                        <p> Name</p>
                        <el-form-item prop="data.name_en">
                            <el-input v-model="Pages.data.name_en"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item mt-20">
                        <p> Name description</p>
                        <el-form-item prop="data.name_en">
                            <el-input v-model="Pages.data.link_dec_en"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item mt-20">
                        <p> Title</p>
                        <el-form-item prop="data.title_en">
                            <el-input v-model="Pages.data.title_en"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <p>Description</p>
                        <el-form-item prop="data.description_en">
                            <el-input type="textarea" :rows="4" v-model="Pages.data.description_en"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item mt-20">
                        <p> Title</p>
                        <el-form-item prop="data.title_en">
                            <el-input v-model="Pages.data.title_en"></el-input>
                        </el-form-item>
                    </div>

                    <div class="item mt-20">
                        <p>Button1 Title</p>
                        <el-form-item prop="data.button1_title_en">
                            <el-input v-model="Pages.data.button1_title_en"></el-input>
                        </el-form-item>
                    </div> 
                     <div class="item mt-20">
                        <p>Button2 Title</p>
                        <el-form-item prop="data.button2_title_en">
                            <el-input v-model="Pages.data.button2_title_en"></el-input>
                        </el-form-item>
                    </div>

                    <h1>Links Blogs</h1>
                    <div v-if="Pages.data.links">
                    <div v-for="(item, i) in Pages.data.links" :key="i">
                        <div class="item mt-20">
                            <p> Title</p>
                            <el-form-item prop="data.links">
                                <el-input v-model="item.title_en"></el-input>
                            </el-form-item>
                        </div>
                        <div style="padding-bottom:20px;" class="flex justify-end">
                            <button class="button red" style="background:red;" @click="deleteBlock(i)">Delete</button>
                        </div>
                    </div>
                    </div>
                    <button type="button" @click="PushBlock" class="button">Add block</button>
                    <h1>Technique</h1>
                    <div class="item mt-20">
                        <p> Title</p>
                        <el-form-item prop="data.technique_title1_en">
                            <el-input v-model="Pages.data.technique_title1_en"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item mt-20">
                        <p> Description</p>
                        <el-form-item prop="data.technique_title2_en">
                            <el-input v-model="Pages.data.technique_title2_en"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <p>Text</p>
                        <el-form-item prop="data.technique_text_en">
                            <el-input type="textarea" :rows="4" v-model="Pages.data.technique_text_en"></el-input>
                        </el-form-item>
                    </div>
                    <h1>Product types</h1>
                    <div v-if="Pages.data.types">
                    <div class="types" v-for="(item,i) in Pages.data.types" :key="i">
                        <h4>{{i+1}}-type</h4>
                        <div class="item mt-20">
                            <p> Title</p>
                            <el-form-item >
                                <el-input v-model="item.title_en"></el-input>
                            </el-form-item>
                        </div>
                        <h5>1-Description</h5>
                        <div class="item mt-20">
                            <p> Title</p>
                            <el-form-item >
                                <el-input v-model="item.description1_en"></el-input>
                            </el-form-item>
                        </div>
                        <h5>2-Description</h5>
                        <div class="item mt-20">
                            <p> Title</p>
                            <el-form-item >
                                <el-input v-model="item.description2_en"></el-input>
                            </el-form-item>
                        </div>

                        <h5>3-Description</h5>
                        <div class="item mt-20">
                            <p> Title</p>
                            <el-form-item >
                                <el-input v-model="item.description3_en"></el-input>
                            </el-form-item>
                        </div>
                        <div class="item mt-20">
                            <p> Button title</p>
                            <el-form-item >
                                <el-input v-model="item.button_en"></el-input>
                            </el-form-item>
                        </div> 
                         
                    </div>
                    </div>
                    <h1>Why Avoir</h1>
                    <div class="item mt-20">
                        <p> Title</p>
                        <el-form-item prop="data.whyText_en">
                            <el-input v-model="Pages.data.whyText_en"></el-input>
                        </el-form-item>
                    </div>
                    <h3>Why descriptions </h3>
                    <div v-if="Pages.data.why"> 
                    <div v-for="(item, i) in Pages.data.why" :key="i">
                        <div class="item mt-20">
                            <p> Title</p>
                            <el-form-item>
                                <el-input v-model="item.title_en"></el-input>
                            </el-form-item>
                        </div>
                        <div class="item">
                            <p>Description</p>
                            <el-form-item>
                                <el-input type="textarea" :rows="4" v-model="item.description_en"></el-input>
                            </el-form-item>
                        </div>
                        <div style="padding-bottom:20px;" class="flex justify-end">
                            <button class="button red" style="background:red;" @click="deleteText(i)">Delete</button>
                        </div>
                    </div>
                    </div>
                    <button type="button" @click="PushText" class="button">Add Block</button>
                    <h1>About us</h1>
                    <div class="item mt-20">
                        <p> Title</p>
                        <el-form-item prop="data.about_title_en">
                            <el-input v-model="Pages.data.about_title_en"></el-input>
                        </el-form-item>
                    </div>

                    <div class="item mt-20">
                        <p> Phone title</p>
                        <el-form-item prop="data.phone_title_en">
                            <el-input v-model="Pages.data.phone_title_en"></el-input>
                        </el-form-item>
                    </div>
                    <h3>Emails</h3>
                    <div v-if="Pages.data.mails">
                    <div v-for="(item, i) in Pages.data.mails" :key="i">
                        <div class="item mt-20">
                            <p> Email</p>
                            <el-form-item>
                                <el-input v-model=" Pages.data.mails[i]"></el-input>
                            </el-form-item>
                        </div>

                        <div style="padding-bottom:20px;" class="flex justify-end">
                            <button class="button red" style="background:red;" @click="deleteMail(i)">Delete</button>
                        </div>
                    </div>
                    </div>
                    <button type="button" @click="PushMail" class="button">Add Mail</button>
                    <div class="item mt-20">
                        <p>Adress</p>
                        <el-form-item prop="data.adress_en">
                            <el-input type="textarea" :rows="4" v-model="Pages.data.adress_en"></el-input>
                        </el-form-item>
                    </div>
                </div>
                <div class="save-buttons">
                    <button class="button" type="button" @click="$router.push('/admin/statpages')"> {{$t('back')}} </button>
                    <button class="button save ml-10" type="button" @click="PostStock()"> Edit</button>
                </div>
            </el-form>
        </div> 

    </div>

</div>
</template>

<script>
export default {
    data() {
        return {
            checked: false,
            langName: 'ua',
            fileList: [],
            value1: '',
            form: {
                name: ''
            },
            loading: false,
            erros: {
                image: false,
                en: false,
                ua: false
            },
            Pages: {
                key: "/leasing",
                data: {
                    name_ua: '',
                    name_en: '',
                    title_ua: '',
                    title_en: '',
                    description_ua: '',
                    description_en: '',
                    button1_title_ua:'',
                    button1_title_en:'',
                    button1_url:'',
                    button2_title_ua:'',
                    button2_title_en:'',
                    linkes: false,
                    credit: false,
                    contact: false,
                    links: [{
                        id: 1,
                        title_ua: '',
                        title_en: '',
                        url: ''
                    }],
                    technique_title1_ua: '',
                    technique_title1_en: '',
                    technique_title2_ua: '',
                    technique_title2_en: '',
                    technique_text_en: '',
                    technique_text_ua: '',
                    whyText_ua: '',
                    whyText_en: '',
                    types:[
                        {
                            title_ua:'',
                            title_en:'',
                            description1_ua:'',
                            description1_en:'',
                            description2_ua:'',
                            description2_en:'',
                            description3_ua:'',
                            description3_en:'',
                            url:'',
                            button_ua:'',
                            button_en:'',
                        },
                        {
                            title_ua:'',
                            title_en:'',
                            description1_ua:'',
                            description1_en:'',
                            description2_ua:'',
                            description2_en:'',
                            description3_ua:'',
                            description3_en:'',
                            url:'',
                            button_ua:'',
                            button_en:'',
                        },
                        {
                            title_ua:'',
                            title_en:'',
                            description1_ua:'',
                            description1_en:'',
                            description2_ua:'',
                            description2_en:'',
                            description3_ua:'',
                            description3_en:'',
                            url:'',
                            button_ua:'',
                            button_en:'',
                        },
                        {
                            title_ua:'',
                            title_en:'',
                            description1_ua:'',
                            description1_en:'',
                            description2_ua:'',
                            description2_en:'',
                            description3_ua:'',
                            description3_en:'',
                            url:'',
                            button_ua:'',
                            button_en:'',
                        },

                    ],
                    why: [{
                        id: 1,
                        title_ua: '',
                        title_en: '',
                        description_ua: '',
                        description_en: ''
                    }],
                    about_title_ua: '',
                    about_title_en: '',
                    phone: '',
                    phone_title_ua: '',
                    phone_title_en: '',
                    mails: [''],
                    adress_ua: '',
                    adress_en: '',

                },
                files: {
                    Image1: '',
                    Image2: '',
                    Image3: '',
                    ImageClouds: '',
                    whyImage1: '',
                    whyImage2: '',
                    whyImage3: '',
                    linksImage1: '', 
                }
            },
            PagesRules: {
                data: {
                    name_ua: [{
                        required: true,
                        message: 'Будь ласка, введіть інформацію',
                        trigger: 'blur'
                    }],
                    name_en: [{
                        required: true,
                        message: 'Будь ласка, введіть інформацію',
                        trigger: 'blur'
                    }],
                    link_dec_ua: [{
                        required: true,
                        message: 'Будь ласка, введіть інформацію',
                        trigger: 'blur'
                    }],
                    link_dec_en: [{
                        required: true,
                        message: 'Будь ласка, введіть інформацію',
                        trigger: 'blur'
                    }],
                    title_ua: [{
                        required: true,
                        message: 'Будь ласка, введіть інформацію',
                        trigger: 'blur'
                    }],
                    title_en: [{
                        required: true,
                        message: 'Будь ласка, введіть інформацію',
                        trigger: 'blur'
                    }],
                    description_ua: [{
                        required: true,
                        message: 'Будь ласка, введіть інформацію',
                        trigger: 'blur'
                    }],
                    description_en: [{
                        required: true,
                        message: 'Будь ласка, введіть інформацію',
                        trigger: 'blur'
                    }],

                },
                files: {

                }

            },
        }

    },

    methods: {
        UploadIMage(event, name) {
            console.log(name)
            this.Pages.files = {
                ...this.Pages.files,
                [name]: event.target.files[0]
            }
        },
        PostStock() {

            this.$refs.pagesForm.validate((valid) => {
                if (valid) {
                    this.loading = true
                    let files = new FormData();
                    var pageData = {}
                    if (typeof this.Pages.data != 'string') {
                        pageData = JSON.stringify(this.Pages.data)
                    }
                    files.append('key', this.Pages.key)
                    files.append('data', pageData)
                    for (let i of Object.keys(this.Pages.files)) {
                        if (this.Pages.files[i].name) {
                            files.append(`files[${i}]`, this.Pages.files[i]);
                        }
                    }

                    this.axios.post(this.Pages.id ? `/admin/statpage/${this.Pages.id}` : '/admin/statpage', files).then(res => {
                            console.log(res)
                            this.$router.push('/admin/statpages')
                            this.$notify({
                                title: 'Успешный',
                                message: 'Информация успешно сохранена',
                                type: 'success'
                            });
                            this.$store.dispatch("get_statpages");
                        })
                        .catch(error => {
                            this.erorrsData = error.response.data.message
                            let ERRORS = error.response.data.errors;
                            let keys = Object.keys(ERRORS);
                            let error_msg = ERRORS[keys[0]];
                            console.log(error_msg[0])
                            this.$notify.error({
                                title: 'Произошла ошибка',
                                message: error_msg ? error_msg[0] : "Произошла ошибка",
                            });

                        })
                        .finally(() => {
                            this.loading = false
                        })
                } else {
                    console.log("AAA")
                }
            })

        },
        GetPages() {
            this.loading = true
            this.axios.get('/admin/statpage')
                .then(res => {
                    var data = res.data.data
                    var home = data.filter(a => {
                        return a.key === '/leasing'
                    })
                    if (home[0]) {
                        this.Pages.id = home[0].id
                        this.Pages.key = home[0].key
                        this.Pages.data = home[0].data
                        for (let i of Object.keys(home[0].files)) {
                            this.Pages.files[i] = home[0].files[i]
                        }

                    }
                })
                .finally(() => {
                    this.loading = false
                })
        },
        PushBlock() {
            var data = {
                id: 1,
                title_ua: '',
                title_en: '',
                url: ''
            }
            var max = 1;
            for (let i = 0; i < this.Pages.data.links.length; i++) {
                if (this.Pages.data.links[i].id > max || this.Pages.data.links[i].id == max) {
                    max = this.Pages.data.links[i].id
                }
            }
            data.id = max + 1
            this.Pages.data.links.push(data)
        },
        PushText() {
            var data = {
                id: 1,
                title_ua: '',
                title_en: '',
                description_ua: '',
                description_en: ''
            }
            var max = 1;
            for (let i = 0; i < this.Pages.data.why.length; i++) {
                if (this.Pages.data.why[i].id > max || this.Pages.data.why[i].id == max) {
                    max = this.Pages.data.why[i].id
                }
            }
            data.id = max + 1
            this.Pages.data.why.push(data)
        },
        PushMail() {
            this.Pages.data.mails.push('')
        },
        deleteText(i) {
            this.Pages.data.why.splice(i, 1)
        },
        deleteMail(i) {
            this.Pages.data.mails.splice(i, 1)
        },
        deleteBlock(i) {
            var key = 'linkImage' + this.Pages.data.links[i].id
            delete this.Pages.files[key]
            this.Pages.data.links.splice(i, 1)
        }

    },
    mounted() {
        this.GetPages()
    }
}
</script>

<style lang="scss" scoped>
.date-line {
    width: 30px;
    height: 3px;
    background: rgba(69, 72, 81, 0.8);
    border-radius: 5px;
    margin: 0px 10px;
}

.save-buttons {
    padding-top: 20px;
}

.upload-image {
    width: 100%;
    display: flex;
    align-items: center;

    p {
        margin-left: 10px;
    }

    button {
        width: 200px;
        position: relative;
        overflow: hidden;
        padding: 10px 15px;
        border-radius: 5px;
        color: var(--white-color);
        font-family: Stem-Medium;
        font-size: 1rem;
        line-height: 1.125rem;
        border: none;
        color: var(--black);

        input {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            opacity: 0;
            z-index: 1;
        }
    }
}

.admin-h2-title {
    margin: 20px 0px 10px 0px;
    font-family: Stem-Medium;
    color: var(--other-black);
}

.error-text {
    color: red;
    font-size: 0.875rem;
    margin-top: 5px;
}

.c-red {
    color: red !important;
}

.add-inputs {
    h1 {
        margin: 20px 0px;
        font-size: 24px;
    }
}
</style>
